/*noinspection CssInvalidAtRule*/
@import-normalize;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'RobotoCondensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(208deg, rgba(15,15,15,1) 0%, rgba(20,20,20,1) 50%, rgba(50,50,50,1) 100%);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: white;
  text-decoration: none;
}

@font-face {
  font-family: 'RobotoCondensed';
  src: local('RobotoCondensed'), url(./fonts/RobotoCondensed-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'RobotoCondensed';
  src: local('RobotoCondensed'), url(./fonts/RobotoCondensed-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'sonder';
  src: local('sonder'), url(./fonts/SonderRegular.otf) format('truetype');
  font-weight: 400;
}
